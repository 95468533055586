import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import Coins from "./Coins";

// export const query = graphql`
//   query($limit: Int!, $skip: Int!) {
//     allMongodbWheretomineCoins(limit: $limit, skip: $skip) {
//       nodes {
//         name
//         algorithm
//         abbreviation
//         numberOfSubPools
//         slug
//       }
//     }
//     allMongodbWheretomineAlgorithms {
//       nodes {
//         name
//         mongodb_id
//         slug
//       }
//     }
//   }
// `;

const CoinsPage = props => {
  // const coins = props.data.allMongodbWheretomineCoins.nodes;
  // const algorithms = props.data.allMongodbWheretomineAlgorithms.nodes;

  const { coins, currentPage, numberOfPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const prevPage = currentPage - 1 === 1 ? 0 : currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <Root>
      <SEO
        title="All Cryptocurrency Mining Pools & Algorithms"
        description="View the best cryptocurrency mining pools for you favorite coins like Bitcoin, Ethereum, Monero, Zcash. Use the profit calculator to see your estimated income."
      />
      <Coins
        coins={coins}
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      />
    </Root>
  );
};

export default CoinsPage;
